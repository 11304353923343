import React, {useEffect} from "react";
import Button from '@material-ui/core/Button';
import RssButton from './RssButton'

function MainPage({setComponent, owner, blogData, BlogTitle, setPost, dir, rssUrl}) {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    const OwnerButtons=({postData, postId})=>{
        return(owner?<div className="btn" style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        width: "100%",
        maxWidth: "180px",
        boxSizing:"border-box",
        flexWrap: "wrap"
    }}>
        <Button className="btnsedit" 
        onClick={()=>{setComponent("editpost")
    setPost({...postData,postId})
    }}
        style={{
            backgroundColor: "#53c4ff",
            color: "white",
            padding: "2px",
            width: "100%",
            boxSizing:"border-box",
            maxWidth: "80px",
            border: "none",
            outline: "none",
            textTransform:"capitalize",
margin: "4px",
            fontSize: "10px"
        }} >Edit</Button>
        {/* <Button className="btnsdel" style={{
            backgroundColor: "#e54646",
            color: "white",
            padding: "2px",
            width: "100%",
            boxSizing:"border-box",
            maxWidth: "80px",
            textTransform:"capitalize",
margin: "4px",
            border: "none",
            outline: "none",
            fontSize: "10px"
        }} >Delete</Button> */}
    </div>:null)
    }

const addPostButton=(owner?
    <Button className="addPostbtn" 
    onClick={()=>{setComponent("Addpost")}}
    style={{
        backgroundColor: "#6c77ff",
        color: "white",
        borderRadius: "4px",
        boxSizing:"border-box",
        width: "100%",
        textTransform:"capitalize",
margin: "4px",
        maxWidth: "100px",
        border: "none",
        outline: "none",
        padding: "6px 0px"
    }} >Add Post</Button>:null)

    const Header=()=>{
        return(<div className="header"  style={{
            width: "100%",
            maxWidth: "1400px",
            fontFamily: 'Segoe UI',
            fontWeight: "bold",
            fontSize: "40px",
            boxSizing:"border-box",
            margin: "auto",
            padding: "4px",
            display: "inline-flex",
            flexDirection: "row",
            justifyContent: "space-between"
        }}>
            {BlogTitle}
            <RssButton rssUrl={rssUrl} />   
    </div>)
    }

        const Buttons=({postData, postId})=>{
            return(<div className="btns" style={{
                display: "flex",
                flexDirection: "row",
                boxSizing:"border-box",
                justifyContent: "space-between",
                alignItems: "center",
                flexWrap: "wrap",
                marginBottom: "20px"
            }}>
                <Button className="btnsread" 
                onClick={()=>{setComponent("artical")
            setPost({...postData,postId})
            }}
                style={{
                    backgroundColor: "#3cc18f",
                    color: "white",
                    padding: "2px",
                    width: "100%",
                    boxSizing:"border-box",
                    maxWidth: "80px",
                    border: "none",
                    outline: "none",
                    fontSize: "10px",
                    textTransform:"capitalize",
margin: "4px",
                }}>Read more</Button>
               
               <OwnerButtons postData={postData} postId={postId}/>
            </div>)
        }

const TextField=({postData, postId})=>{
    return(<div className="readcontet" style={{
        width: "100%",
        boxSizing:"border-box",
        maxWidth: "800px",
    }}>
        
        <div className="readcontenttitle" style={{
            fontFamily: 'Segoe UI',
            fontWeight: "bold",
            boxSizing:"border-box",
            fontSize: "28px",
            color: "black",
            textTransform: "uppercase",
            margin: "12px 0px"
        }}>
            {postData.title}
    </div>
        <div className="readcontentread" style={{
            fontFamily: 'Segoe UI',
            fontSize: "12px",
            fontWeight: "400",
            boxSizing:"border-box",
            color: "#707070",
            marginBottom: "10px"
        }}>
            {postData.summary}
            </div>
        
        <Buttons postData={postData} postId={postId}/>

    </div>)
}

const Img=({imageUrl})=>{
    // if(!imageUrl)return null
    return(<div className="images" style={{
        width: "100%",
        maxWidth: "200px",
        boxSizing:"border-box",
    }}>
        <img className="image" style={{
            width: "inherit",
            boxSizing:"border-box",
            height: "inherit",
            maxHeight:"200px",
            objectFit: "fill"
        }} src={imageUrl} alt="" />
    </div>)
}

const SinglePost=({postData, i, postId})=>{
    // console.log("key:  ",key)
    // if(postData.imageUrl){photosCounter++}
return(<div className="readPost" style={{
    width: "96%",
    maxWidth: "1200px",
    margin: "auto",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    flexWrap: "wrap-reverse",
    backgroundColor: "white",
    boxSizing:"border-box",
    marginTop: "40px",
    padding: "10px"
}}>
    {((i%2)===0)&&<Img imageUrl={postData.imageUrl}/>}
   <TextField postData={postData} postId={postId}/>
   {((i%2)!=0)&&<Img imageUrl={postData.imageUrl}/>}
   
   
 
</div>)
}








    return (
        <div 
        dir={dir}
        style={{
            width: document.activeElement.clientWidth<=240 ? "96%" : "100%" ,
            margin:"auto",
            boxSizing:"border-box",
            padding:"0px",
        }}>
           <Header />
            <div className="postcontent" style={{
                                            width: "100%",
                                            boxSizing:"border-box",
                                            backgroundColor: "#eef0f8",
                                            paddingBottom: "30px"
                                        }}>
                <div className="addpost" style={{
                                            width: "100%",
                                            maxWidth: "1360px",
                                            boxSizing:"border-box",
                                            margin: "auto",
                                            padding: "20px 0px 20px 2%",
                                            borderBottom: "2px solid #5381ff"
                                            }}>
                                           
                {addPostButton}
                </div>

                {Object.keys(blogData).sort().reverse().map((postId, i)=>{
                    return(<SinglePost key={i} i={i} postData={blogData[postId]} postId={postId}/>)
                    
                })}
                
                
                
                
                {/* <Button className="loadmore" style={{
                    display: "block",
                    width: "100%",
                    boxSizing:"border-box",
                    maxWidth: "120px",
                    color: "white",
                    backgroundColor: "#5381ff",
                    textTransform:"capitalize",
margin: "4px",
                    padding: "4px 0px",
                    margin: "30px auto",
                    marginBottom: "0px",
                    border: "none",
                    outline: "none"
                }}>Load More</Button> */}
            </div>
            {(Object.keys(blogData).length>4)&&<div><div className="commentContent" style={{
                width: "100%",
                maxWidth: "1400px",
                boxSizing:"border-box",
                margin: "auto",
                padding: "30px 0px",
                borderBottom: "2px solid #5381ff"
            }}></div>
            <h6>Component by: <a href="https://dugoly.com/">dugoly - developers tools</a>, developed by: <a href="https://motto.co.il/">motto</a> </h6>
        </div>}
        </div>
    );
}

export default MainPage;