import React from 'react'
const RssButton=({rssUrl})=>{

    return(<div>
        
        <button><img src="http://www.easydone.online/uploads/-MO0rfu7iS6SO7iFQS2x/169a1e49-11eb-4e2c-8501-b39330e17e65.png"
               alt="my image" style={{width:"50px"}} onClick={()=>{alert("the url for the rss is: "+rssUrl)}} /></button>
    </div>)
}

export default RssButton